import { Link } from 'gatsby'
import React from 'react'
import { cn, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from './block-text'
import Img from 'gatsby-image'

import styles from './project-preview.module.css'
import { responsiveTitle3 } from './typography.module.css'

function ProjectPreview (props) {
  return (
    <Link className={styles.root} to={`/work/${props.slug.current}`}>
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <Img 
            alt={props.mainImage.alt}
            className={styles.image} 
            fluid={props.mainImage.asset.fluid}  
            objectFit 
          />
        )}
      </div>
      <div className={styles.textGroup}>

        <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
        {props.client && (
          <div className={styles.client}>
            { props.client}
          </div>
        )}
      </div>
    </Link>
  )
}

export default ProjectPreview
